import React from "react"
import { Subscribe } from "unstated"
import NotificationStore from "../../stores/notifications"

import Layout from "./components/_Layout"
import NotificationsLists from "./components/_NotificationsList"

function Notifications() {
  return (
    <Layout
      name="Notifications"
      title="View all your notifications and make sure that you haven't missed anything"
    >
      <Subscribe to={[NotificationStore]}>
        {notificationStore => <NotificationsLists store={notificationStore} />}
      </Subscribe>
    </Layout>
  )
}

export default Notifications
