import React from "react"
import styled from "styled-components"

import { get } from "lodash"

import Header from "./_Header"
import withLayout from "../../../components/layout"

const Container = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 14px;
  line-height: 1.2;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  p,
  a {
    font-size: 14px;
    line-height: 1.2;
  }

  .gray,
  .small {
    color: #9ba1aa;
  }

  .small {
    font-size: 12px;
  }

  .strong {
    text-transform: uppercase;
    color: #6e6d7a;
  }

  .green {
    color: #6abf83;
  }

  .purple {
    color: #6377f1;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }
`

const PersonalInfoDiv = styled.div`
  // z-index: 1;
  // position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  box-sizing: border-box;
`

const OrdersContainer = styled.div`
  flex: 1;

  @media screen and (min-width: 961px) {
    padding-left: 30px;
    margin-left: 180px;
  }
`

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    padding: 30px;
    padding-top: 0px;
  }
`

function Layout({ authStore, name, title, children }) {
  const email = get(authStore, "state.user.email", "email not found")
  const userID = get(authStore, "state.user.user_id", null)
  return (
    <PageContainer>
      <Header email={email} name={name} title={title} userID={userID} />
      <Container>
        <OrdersContainer>
          <PersonalInfoDiv>{children}</PersonalInfoDiv>
        </OrdersContainer>
      </Container>
    </PageContainer>
  )
}

export default withLayout(Layout)
